import Cleave from "cleave.js";

export default class extends ApplicationController {
  static masked;

  connect() {
    $(document).on("select2:open", `#${this.element.id}`, (event) => {
      if (this.input) {
        this.input.addEventListener("keypress", this.handleKeypress.bind(this));
      }

      return this.start(event);
    });

    $(document).on("select2:close", `#${this.element.id}`, (event) => {
      if (this.input) {
        this.input.addEventListener("keypress", this.handleKeypress);
      }

      return this.clear(event);
    });
  }

  start(event) {
    if (this.input) {
      this.masked = new Cleave(
        this.input,
        Object.assign(
          {},
          {
            numericOnly: true,
            delimiter: " ",
            delimiterLazyShow: true,
            noImmediatePrefix: false,
            prefix: "CZ",
            blocks: [4, 6],
          }
        )
      );
    }
  }

  clear(event) {
    if (this.input) {
      this.masked = null;
    }
  }

  handleKeypress(event) {
    const keyCode = event.which ? event.which : event.keyCode;
    const { value } = event.currentTarget;

    if (value.length > 10 || !(keyCode >= 48 && keyCode <= 57)) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  // Private

  get input() {
    return document.querySelector("input.select2-search__field");
  }
}
