import { DirectUpload } from "@rails/activestorage";
import { errorToConsole } from "../lib/utils";

const readFileURL = (file, handleLoad, handleProgress) => {
  if (file) {
    const reader = new FileReader();

    reader.onload = ({ target: { result } }) => handleLoad(result);
    reader.readAsDataURL(file);
  }
};

const uploadFile = (file, url, ref) => {
  if (file) {
    const upload = new DirectUpload(file, url, ref);

    upload.create((err, blob) => {
      if (err) {
        errorToConsole("FileUpload", err);
      } else {
        readFileURL(file, (endpoint) => createFilePreview(endpoint, blob, ref));
      }
    });
  }
};

const createFilePreview = (url, blob, ref) => {
  console.log("create preview")
  let icon = ref.iconValue ? ref.iconValue : 'fa-file-pdf'
  ref.holder.insertAdjacentHTML(
    "beforeend",
    blob.content_type.includes('image') && !ref.iconValue ? `<div data-controller="photo-removal" data-photo-removal-url-value="/photos/${blob.signed_id}/blob/remove" class="photo__container mb-3">
      <img src="${url}" class="img-fluid mb-1" />
      <button type="button" class="photo__removal-button btn btn-sm" data-action="click->photo-removal#remove"><i class="fa fa-trash"></i></button>
      <input type="hidden" value="${blob.signed_id}" name=${ref.input.name}>
    </div>` : `<div data-controller="photo-removal" data-photo-removal-url-value="/photos/${blob.signed_id}/blob/remove" class="photo__container mb-3">
      <div class="row">
        <div class="col"><i class="fas ${icon} text-success" style="font-size: 400%"></i></div>
        <div class="col d-flex"><span class="mt-auto ml-auto mr-2">${blob.filename}</span></div>
      </div>
      <button type="button" class="photo__removal-button btn btn-sm" data-action="click->photo-removal#remove"><i class="fa fa-trash"></i></button>
      <input type="hidden" value="${blob.signed_id}" name=${ref.input.name}>
    </div>`
  );
};

export default class extends ApplicationController {
  static values = { url: String, icon: String };
  static targets = ["holder", "input"];
  static loaded = 0;
  static total = 0;
  static loadedFiles = 0;
  static totalFiles = 0;

  upload(event) {
    this.initializeProgress();

    const collection = this.files;

    Array.from(collection).forEach((file) => {
      this.total += file.size;
    });

    Array.from(collection).forEach((file) =>
      uploadFile(file, this.uploadUrl, this)
    );

    this.input.value = "";
  }

  // Private

  directUploadWillStoreFileWithXHR(req) {
    req.upload.addEventListener("loadstart", (event) =>
      this.directUploadDidStart(event)
    );

    req.upload.addEventListener("progress", (event) =>
      this.directUploadDidProgress(event)
    );

    req.upload.addEventListener("loadend", (event) =>
      this.directUploadDidEnd(event)
    );
  }

  directUploadDidStart(event) {
    if (this.progressElement) {
      this.progressElement.classList.remove("direct-upload--pending");
    }
  }

  directUploadDidProgress(event) {
    const previousProgress = Math.round((this.loaded / this.total) * 100);
    this.loaded += event.loaded;
    this.total += event.total;
    const currentProgress = Math.round((this.loaded / this.total) * 100);
    const progress =
      currentProgress < previousProgress ? previousProgress : currentProgress;

    if (this.progressElement) {
      const progressBar = this.progressElement.querySelector(
        "#direct-upload-progress__bar"
      );

      const progressText = this.progressElement.querySelector(
        "#direct-upload-progress__text"
      );

      progressBar.style.width = `${progress}%`;
      progressText.textContent = `${progress}%`;
    }
  }

  directUploadDidEnd(event) {
    this.loadedFiles += 1;

    if (this.loadedFiles >= this.totalFiles && this.progressElement) {
      this.progressElement.remove();
      this.toggleElements(false);
    }
  }

  initializeProgress() {
    this.loaded = 0;
    this.total = 0;

    this.loadedFiles = 0;
    this.totalFiles = this.files.length;

    this.toggleElements(true);

    this.holder.insertAdjacentHTML(
      "beforebegin",
      `
        <div id="direct-upload-progress" class="direct-upload direct-upload--pending">
          <div id="direct-upload-progress__bar" class="direct-upload__progress" style="width: 0%"></div>
          <span id="direct-upload-progress__text" class="direct-upload__filename"></span>
        </div>
      `
    );
  }

  toggleElements(predicate) {
    this.input.disabled = predicate;
    const form = this.input.closest("form");

    if (form) {
      const submit = form.querySelector("input[type=submit]");

      if (submit) submit.disabled = predicate;
    }
  }

  get files() {
    return this.input.files;
  }

  get holder() {
    return this.holderTarget;
  }

  get progressElement() {
    return document.getElementById("direct-upload-progress");
  }

  get input() {
    return this.inputTarget;
  }

  get uploadUrl() {
    if (this.hasInputTarget) {
      return this.inputTarget.dataset.directUploadUrl;
    }
  }
}
