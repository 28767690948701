require("select2");
const CsSelect2Locale = require("select2/src/js/select2/i18n/cs");
const Translation = require("select2/src/js/select2/translation");
Translation._cache["./i18n/cs"] = CsSelect2Locale;
Translation._cache["cs"] = CsSelect2Locale;

export default class extends ApplicationController {
  static select;
  static values = {
    input: String,
    label: String,
    multiple: Boolean,
    queryParam: String,
    remote: Boolean,
    url: String,
  };

  connect() {
    this.select = $(this.element).select2(this.options);
  }

  disconnect() {
    $(this.element).select2("destroy");
  }

  templateResult(item) {
    return $(`<span title="${item.id}">${item.text}</span>`);
  }

  get isRemote() {
    return this.hasRemoteValue && this.remoteValue === true && this.hasUrlValue;
  }

  get queryParam() {
    return this.hasQueryParamValue ? this.queryParamValue : "name_cont";
  }

  get options() {
    const baseOpts = {
      language: CsSelect2Locale,
      minimumResultsForSearch: 0,
      theme: "bootstrap",
      width: "100%",
      templateResult: this.templateResult,
    };

    const remoteopts = this.isRemote
      ? {
          ajax: {
            url: this.urlValue,
            dataType: "json",
            delay: 250,
            data: (params) => {
              return {
                q: {
                  [`${this.queryParam}`]: params.term,
                },
                page: params.page || 1,
              };
            },
            processResults: (result, params) => {
              params.page = params.page || 1;
              return {
                results: result.data.map((result) => {
                  return {
                    id: result[this.inputValue],
                    text: result[this.labelValue],
                  };
                }),
                pagination: {
                  more: result.pagination.more,
                },
              };
            },
            cache: true,
          },
        }
      : {};

    return {
      ...baseOpts,
      ...remoteopts,
    };
  }
}
