import Rails from '@rails/ujs'

submitFilterTimeout = undefined

$(document).on 'change', '[data-behavior~=submit_search_form_change]:not(select[data-behavior~=submit_search_form_change])', (evt) ->
  submitSearchForm(evt)

$(document).on 'select2:select', '[data-behavior~=submit_search_form_change]', (evt) ->
  submitSearchForm(evt)

$(document).on 'select2:unselect', '[data-behavior~=submit_search_form_change]', (evt) ->
  submitSearchForm(evt)

$(document).on 'input change paste', '[data-behavior~=submit_search_form_blur]', (evt) ->
  clearTimeout(submitFilterTimeout)
  submitFilterTimeout = setTimeout (->
    submitSearchForm(evt)
    setFocusTo(evt.target)
  ), 2000

preventDefaultAndStopPropagation = (evt) ->
  evt.preventDefault()
  evt.stopPropagation()

submitSearchForm = (evt) ->
  preventDefaultAndStopPropagation(evt)
  target = evt.target
  form = $(target).closest('form')

  if (form)
    Rails.fire(form[0], 'submit')

setFocusTo = (el) ->
  setTimeout (->
    element = document.getElementById(el.id)
    if element
      element.focus()
      # element.setSelectionRange(100, 100)
  ), 300
