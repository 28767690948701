export default class extends ApplicationController {
    static targets = [
        "gamekeepers", "template", "templateText", "tbody", "row", "numbers",
        "numbersTemplate", "autoNumbering", "radioNumbering",
        "number", "button", "modal", "modalTemplate", "templateTarget",
        "addAll", "removeAll", "custom"
    ]

    static values = { numberLabel: String, firstNumberLabel: String }

    initialize() {
        console.log("init permit controller:")

        if (typeof animals_data == 'undefined') return

        this.rowTargets.forEach(function (e, i) {
            this.set_item(e, $(e).find('input').val(), $(e).find('label')[0].innerHTML)
            this.selectGender(null, e)
        }, this)

        this.numbering = $(this.autoNumberingTarget).find("input:checked").val()

        if (this.hasGamekeepersTarget) {
            $(this.gamekeepersTarget).on('select2:select', {controller: this}, this.gamekeepers)
            $(this.gamekeepersTarget).on('select2:unselect', {controller: this}, this.gamekeepers)

            Object.entries(gamekeepers_data).forEach(g => {
                $(this.gamekeepersTarget).append(new Option(g[1].text, g[1].id, true, g[1].selected))
            })

            $(this.gamekeepersTarget).select2({
                sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),
                sortResults: data => data.sort((a, b) => a.text.localeCompare(b.text)),
            })
            this.gamekeepers({target: this.gamekeepersTarget, data: {controller: this}})
        }

    }

    setNumber(e) {
        let number = $(e.target).val()
        let id = $(e.target.parentElement).find(".number").val()
        gamekeepers_data[id].number = number
    }

    gamekeepers(e) {
        const me = e.data.controller
        let data = $(e.target).select2('data')

        me.set_buttons()

        if (data.length > 1) {
            $(me.autoNumberingTarget).css("display", "block")
            if (me.numbering == "auto") {
                //zobrazit cisla
                $(me.numberTarget).find("label")[0].childNodes[1].nodeValue = me.firstNumberLabelValue
                $(this.numberTarget).removeClass("d-none")
                return
            } else {
                //hide cisla
                $(me.numberTarget).find("label")[0].childNodes[1].nodeValue = me.numberLabelValue
                $(this.numberTarget).addClass("d-none")
            }

        } else {
            //$(me.autoNumberingTarget).checked("auto")
            $(me.numberTarget).find("label")[0].childNodes[1].nodeValue = me.numberLabelValue
            $(me.autoNumberingTarget).css("display", "none")
            $(me.autoNumberingTarget).trigger('change')
            return
        }

        if (data.length > 0) {
            let ids = data.map(i => i.id)

            me.buttonTargets.forEach(function (b) {
                let id = $(b).attr("data-id")
                if (ids.includes(id)) {
                    $(b).attr('selected', true)
                    if (gamekeepers_data[id].number) {
                        $(b).find(".number_id").val(gamekeepers_data[id].number)
                    }
                    if (gamekeepers_data[id].error) {
                        let error = $(b).find(".invalid-feedback")
                        error.text(gamekeepers_data[id].error.join(', '))
                        $(b).find(".number_id").addClass("is-invalid")
                        $(error)
                    }
                } else {
                    $(b).attr('selected', false)
                }
            })
        }
    }


    autoNumbering(e) {
        console.log("auto:", e.target.value)
        this.numbering = e.target.value
        this.gamekeepers({target: this.gamekeepersTarget, data: {controller: this}})
        this.set_buttons()
    }

    select_animal(e) {
        this.add_item(e.target)
        $(e.target).val(null).trigger('change');

    }

    before_submit(e) {
        this.rowTargets.forEach(function (e, i) {
            let items = $(e).find("[permit]")
            $(items).each(function () {
                console.log("--------------:", $(this).attr("name"))
                $(this).removeAttr("id")
                $(this).attr("name", $(this).attr("name").replace(/items_attributes]\[\d*]/, `items_attributes][${i}]`))
                $(this).attr("name", $(this).attr("name").replace("item[", `permit[items_attributes][${i}][`))
                console.log("--------------:", $(this).attr("name"))
            })
            console.log("items:", items)
        })
    }


    add_item(item) {
        let id = item.value
        let row
        let text = item.selectedOptions[0].text
        let last_row = null

        $(this.rowTargets).each(function(el){
            const animal_id = $(this).find("[permit='animal']").val()
            console.log("animal:", animal_id, id)
            if (animal_id == id) {
                last_row = this
            }
        })

        if (id == "0") {
            //this.showCustom()
            //return
            row = this.templateTextTarget.content.firstElementChild.cloneNode(true)
            this.tbodyTarget.append(row)
        } else {
            row = this.templateTarget.content.firstElementChild.cloneNode(true)
            if (last_row) {
                $(row).insertAfter($(last_row))
            } else {
                this.tbodyTarget.append(row)
            }
        }
        this.set_item(row, id, text)
    }

    set_item(row, id, text) {
        let active = this.initActive(row)
        let me = this

        $(active.animal).val(id)
        $(active.gender).select2()
        $(active.gender).on('select2:unselect', function (e) {
            me.selectGender(e)
        });
        $(active.age).select2()
        $(row).find(".permit").text(text)


        active.count.removeAttribute('disabled')
        let ad = animals_data[id]
        if (ad && ad.genders) {
            let item_id = $(row).find("[permit='id']").val()
            $(active.gender).select2({data: ad.genders})
            if (ad.sel && ad.sel[item_id]) {
                $(active.gender).val(ad.sel[item_id])
                $(active.gender).trigger('change')
            }
            active.gender.removeAttribute('disabled')
        }

    }

    initActive(e) {
        let ret = this.setActive(e)
        for (const [key, value] of Object.entries(ret)) {
            value.removeAttribute('id')
        }
        return ret
    }

    setActive(e) {
        let ret = {}
        let permit
        $(e).find("[permit]").each(function () {
            permit = $(this).attr("permit")
            ret[permit] = this
        })
        return ret
    }

    delete(e) {
        e.preventDefault();
        let parent = $(e.target).parents("tr")[0]
        if ($(parent).find("[permit='id']").val() == "") {
            parent.remove()
        } else {
            $(parent).find("[permit='destroy']").val(true)
            $(parent).hide()
        }
    }

    selectGender(e, t) {
        let target = e ? e.target : t
        let active = t ? this.setActive($(t)) : this.setActive($(target).parents("tr")[0])
        if (active.animal.value == "") return

        let all = $(active.gender).find(":selected").length
        let male = $(active.gender).find(":selected[value='samec']").length
        let female = $(active.gender).find(":selected[value='samice']").length
        let cub = $(active.gender).find(":selected[value='mládě']").length
        let ages = animals_data[$(active.animal).val()] ? animals_data[$(active.animal).val()].ages : null

        if (all < 1 || !ages || male < 1) {
            //$(active.age).val(null).trigger('change')
            //active.age.setAttribute("disabled", '')
        } else {
            //active.age.removeAttribute("disabled")
        }

        if (all > 0) {
            this.setVisibility(active.count.parentElement, false)
        } else {
            this.setVisibility(active.count.parentElement, true)
        }
        if (male > 0) {
            this.setVisibility(active.count_mi.parentElement, true)
            this.setVisibility(active.count_mii.parentElement, true)
            this.setVisibility(active.count_miii.parentElement, true)
        } else {
            this.setVisibility(active.count_mi.parentElement, false)
            this.setVisibility(active.count_mii.parentElement, false)
            this.setVisibility(active.count_miii.parentElement, false)
        }
        if (female > 0) {
            this.setVisibility(active.count_f.parentElement, true)
        } else {
            this.setVisibility(active.count_f.parentElement, false)
        }
        if (cub > 0) {
            this.setVisibility(active.count_c.parentElement, true)
        } else {
            this.setVisibility(active.count_c.parentElement, false)
        }

    }

    setVisibility(obj, dir) {
        if (dir) {
            $(obj).addClass("d-flex")
            $(obj).removeClass("d-none")
        } else {
            $(obj).addClass("d-none")
            $(obj).removeClass("d-flex")
        }
    }

    add(e) {
        e.preventDefault();
        let ids = $(this.gamekeepersTarget).val()
        let id = $(e.target).attr("id")
        let sel = ids.includes(id)

        if (sel) {
            let values = $(this.gamekeepersTarget).val()
            if (values) {
                let i = values.indexOf(id);
                if (i >= 0) {
                    values.splice(i, 1);
                }
            }
            $(this.gamekeepersTarget).val(values)
            //$(e.target).attr("selected", false)
        } else {
            let selected = $(this.gamekeepersTarget).select2('data').map(d => d.id)
            $(this.gamekeepersTarget).val([...selected, id])
            //$(e.target).attr("selected", true)
        }

        $(this.gamekeepersTarget).trigger('change');

        this.set_buttons()
        this.gamekeepers({target: this.gamekeepersTarget, data: {controller: this}})
    }

    addAll(e) {
        e.preventDefault();
        console.log(e.target)
        $(this.gamekeepersTarget).val(Object.entries(gamekeepers_data).map(
            i => i[0]
            )
        )
        $(this.gamekeepersTarget).trigger('change')
        this.gamekeepers({target: this.gamekeepersTarget, data: {controller: this}})
    }

    removeAll(e) {
        e.preventDefault();
        console.log(e.target)
        $(this.gamekeepersTarget).val([])
        $(this.gamekeepersTarget).trigger('change')
        this.gamekeepers({target: this.gamekeepersTarget, data: {controller: this}})
    }

    set_buttons() {
        let selected = $(this.gamekeepersTarget).select2('data').map(d => d.id)
        this.buttonTargets.forEach(bc => {
            let b = $(bc).find("a")
            let n = $(bc).find(".number_id")
            let num = $(bc).find(".number")
            if (selected.includes($(b).attr('id'))) {
                $(b).addClass("btn-warning")
                $(b).removeClass("btn-outline-secondary")
                if (this.numbering == "manual" && selected.length > 1) {
                    $(n).removeClass("d-none")
                    $(n).attr("disabled", false)
                    $(num).attr("disabled", false)
                } else {
                    $(n).addClass("d-none")
                    $(n).addClass("disabled")
                }
            } else {
                $(b).addClass("btn-outline-secondary")
                $(b).removeClass("btn-warning")
                $(n).addClass("d-none")
                $(n).attr("disabled", true)
                $(num).attr("disabled", true)
            }
        })

        if (selected.length > 0) {
            $(this.removeAllTarget).removeClass("disabled")
        } else {
            $(this.removeAllTarget).addClass("disabled")
        }
        if (selected.length != this.buttonTargets.length) {
            $(this.addAllTarget).removeClass("disabled")
        } else {
            $(this.addAllTarget).addClass("disabled")
        }
    }

    checkCount(e) {
        if (e.target.value == "-1") {
            e.target.value = ""
        }
    }

    // checkCountMale(e) {
    //     console.log("ff")
    //     if (!parseInt(e.target.value) >= 1) {
    //         e.target.value = 0
    //     }
    // }

    showModal(e) {
        e.preventDefault();
        const url = $(e.target).attr("url")
        const preloader = this.modalTemplateTarget.content.firstElementChild.cloneNode(true)
        const modal = $(".modal")
        const modalContext = this.templateTargetTarget
        $(modalContext).html($(preloader))
        modal.modal('show')
        console.log("show modal:", url)
        fetch(url, {
            method: 'GET',
            dataType: 'script',
            headers: {
                "X-CSRF-Token": this.getMetaValue("csrf-token"),
                "Content-Type": "application/json"
            },
        })
            .then(response => response.text())
            .then(html => {
                console.log("load modal context:", html)
                $(modalContext).html(html)
            })
    }

    getMetaValue(name) {
        const element = document.head.querySelector(`meta[name="${name}"]`)
        return element.getAttribute("content")
    }

    // showCustom() {
    //     let table = $(this.customTarget)
    //     table.removeClass("visually-hidden")
    //     table.find(".custom_text").attr("disabled", false)
    // }
    //
    // hideCustom(e) {
    //     e.preventDefault();
    //     let table = $(this.customTarget)
    //     table.addClass("visually-hidden")
    //     table.find(".custom_text").val("")
    // }
}
