class InlineForm
  constructor: ->
    document.addEventListener "turbolinks:load", @dom_refresh

  dom_refresh: =>
    for element in document.querySelectorAll "[data-role~=remote_form], [data-role~=remote_link]"
      element.addEventListener "ajax:complete", @remote_form_complete

  remote_form_complete: (event) =>
    target = event.target

    if (target && target.tagName == "A" && target.getAttribute('data-role') != 'remote_link')
      return

    xhr = event.detail.filter((object) -> object.constructor is XMLHttpRequest)[0]

    unless xhr.getResponseHeader "Location"
      isSearchForm = target.getAttribute('data-search-form')
      if isSearchForm
        responseURL = xhr.responseURL
        if responseURL
          window.history.replaceState {}, '', decodeURIComponent(responseURL)

      referrer = window.location.href
      current_snapshot = Turbolinks.Snapshot.fromHTMLElement document
      new_snapshot = Turbolinks.Snapshot.wrap xhr.responseText
      Turbolinks.controller.currentVisit = Turbolinks.controller.createVisit referrer, "restore", {}
      Turbolinks.clearCache()
      Turbolinks.SnapshotRenderer.render Turbolinks.controller, @complete, current_snapshot, new_snapshot

  complete: =>
    Turbolinks.dispatch "turbolinks:load"
    document.body.querySelector("input.is-invalid, select.is-invalid")?.focus()

new InlineForm
