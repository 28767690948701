import { chunk } from "./utils";
import { request } from "../helpers";

const THRESHOLD = 500;
class Coords {
  constructor(latitude = null, longitude = null, radius = null) {
    this.latitude = latitude;
    this.longitude = longitude;
    this.radius = radius;
  }

  getRadiusPoints() {
    const d = (this.radius * 1000) / 6371000;
    const lat = (Math.PI / 180) * this.latitude;
    const lon = (Math.PI / 180) * this.longitude;
    let points = [];

    for (let i = 0; i < 360; i++) {
      const degrees = (Math.PI / 180) * i;
      const y = Math.asin(
        Math.sin(lat) * Math.cos(d) +
          Math.cos(lat) * Math.sin(d) * Math.cos(degrees)
      );
      const deltaLon = Math.atan2(
        Math.sin(degrees) * Math.sin(d) * Math.cos(lat),
        Math.cos(d) - Math.sin(lat) * Math.sin(y)
      );
      const x = ((lon - deltaLon + Math.PI) % (2 * Math.PI)) - Math.PI;

      const point = SMap.Coords.fromWGS84(
        parseFloat(x * (180 / Math.PI)),
        parseFloat(y * (180 / Math.PI))
      );

      points.push(point);
    }

    return points;
  }

  getZoomPoints() {
    if (
      this.latitude === null &&
      this.longitude === null &&
      this.radius === null
    )
      return null;

    return this.getRadiusPoints();
  }
}

class Geometry {
  constructor(element, dataSource, map, options) {
    this.element = element;
    this.options = options || {};
    this.map = map;
    this.dataSource = dataSource;

    fetchDataSource(this, dataSource);
  }

  render() {
    this.processData();
    renderGeometry(this.geometryName(), this);
  }

  center() {
    if (this.coordinates.length > 0) {
      const computed = this.map.computeCenterZoom(this.coordinates);
      const [center, zoom] = computed;
      this.map.setCenterZoom(center, zoom);
    }
  }
}

class MarkerGeometry extends Geometry {
  processData() {
    const [coordinates, data] = processMarkers(this);

    this.data = data;
    this.coordinates = (this.coordinates || []).concat(coordinates);

    chunk(this.data, THRESHOLD).forEach((markers, idx) => {
      const layer = new SMap.Layer.Marker(`${this.element}-${idx}`);

      layer.addMarker(markers);

      if (this.options["cluster"] === true) {
        const clusterer = this.configClusterer();
        layer.setClusterer(clusterer);
      }

      this.map.addLayer(layer).enable();
    });
  }

  geometryName() {
    return "MarkerGeometry";
  }

  configClusterer() {
    const CustomClusterer = JAK.ClassMaker.makeClass({
      NAME: "CustomClusterer",
      VERSION: "1.0",
      EXTEND: SMap.Marker.Cluster,
    });

    CustomClusterer.prototype.click = function (e, elm) {
      const zoom = 18;
      const m = this.getMap();

      if (m.getZoom() >= zoom) {
        const card = new SMap.Card();
        let panel = "";

        for (i = 0; i < this._markers.length; i++) {
          panel += this._markers[i]._card.getBody().innerHTML;
        }

        card.getContainer().classList.add("map-card");
        card.getBody().innerHTML = panel;
        m.addCard(card, this.getCoords());
      } else {
        this.$super(e, elm);
      }
    };

    new SMap.Marker.Clusterer(this.map, 25, CustomClusterer);
  }
}

class PolygonGeometry extends Geometry {
  processData() {
    const [points, polygons] = processPolygons(this);

    this.data = polygons;
    this.coordinates = (this.coordinates || []).concat(points);

    chunk(this.data, THRESHOLD).forEach((polygons, idx) => {
      const layer = new SMap.Layer.Geometry(`${this.element}-${idx}`);

      polygons.forEach((polygon) => layer.addGeometry(polygon));
      this.map.addLayer(layer).enable();
    });
  }

  geometryName() {
    return "PolygonGeometry";
  }
}

const Mapkick = {
  Coords: Coords,
  MarkerGeometry: MarkerGeometry,
  PolygonGeometry: PolygonGeometry,
  geometries: {},
};

if (typeof window !== "undefined" && !window.Mapkick) {
  window.Mapkick = Mapkick;
}

Mapkick.default = Mapkick;
export default Mapkick;

export const fetchDataSource = async (instance, dataSource) => {
  if (typeof dataSource === "string") {
    try {
      const response = await request.get(dataSource, {
        contentType: "application/json",
        responseKind: "json",
      });
      instance.rawData = response;
      errorCatcher(instance);
    } catch (err) {
      errorToConsole(err.message);
    }
  } else if (typeof dataSource === "function") {
    try {
      dataSource(
        (data) => {
          instance.rawData = data;
          errorCatcher(instance);
        },
        (message) => errorToConsole(message)
      );
    } catch (err) {
      errorToConsole(err.message);
    }
  } else {
    instance.rawData = dataSource;
    errorCatcher(instance);
  }
};

const renderGeometry = (geometryType, instance) => {
  instance.center();
};

const processMarkers = (instance) => {
  let markers = [];
  let coordinates = [];

  instance.rawData.forEach((item, idx) => {
    const { latitude, longitude, popup, icon } = item;
    const url = icon || instance.options["icon"];
    const coords = SMap.Coords.fromWGS84(longitude, latitude);

    coordinates = coordinates.concat(coords);
    const marker = new SMap.Marker(coords, null, { url });

    if (popup) {
      const card = new SMap.Card();
      card.getContainer().classList.add("map-card");
      card.getBody().innerHTML = popup;
      marker.decorate(SMap.Marker.Feature.Card, card);
    }

    markers.push(marker);
  });

  return [coordinates, markers];
};

const processPolygons = (instance) => {
  let polygons = [];
  let points = [];

  instance.rawData.forEach((item) => {
    const { id, coordinates, options } = item;
    let coords = [];

    coordinates.forEach(([latitude, longitude]) => {
      const point = SMap.Coords.fromWGS84(longitude, latitude);
      coords.push(point);
      points.push(point);
    });

    const polygon = new SMap.Geometry(
      SMap.GEOMETRY_POLYGON,
      id,
      coords,
      {
        ...options,
        opacity: 0,
        outlineColor: '#ffffff',
        outlineOpacity: 1,
        outlineWidth: 4
      }
    );

    polygons.push(polygon);
  });

  return [points, polygons];
};

const errorCatcher = (instance) => {
  try {
    instance.render();
  } catch (err) {
    errorToConsole(err.message);
    throw err;
  }
};

const errorToConsole = (value) => {
  const message = `🚨 %c[Mapkick Error]: ${value}`;
  console.log(message, "color: #ff0000; font-weight:bold;");
};
