export default class extends ApplicationController {
  static classes = [ 'show', 'hide' ]
  static targets = [ 'icon', 'input' ]

  connect() {
    if (this.hasInputTarget) {
      if (this.inputType === 'password') {
        this.iconTarget.classList.remove(this.showClass)
        this.iconTarget.classList.add(this.hideClass)
      } else {
        this.iconTarget.classList.remove(this.hideClass)
        this.iconTarget.classList.add(this.showClass)
      }
    }
  }

  toggle(event) {
    event.preventDefault()
    event.stopPropagation()

    if (this.hasInputTarget) {
      if (this.inputType === 'password') {
        this.iconTarget.classList.remove(this.hideClass)
        this.iconTarget.classList.add(this.showClass)
        this.inputType = 'text'
      } else {
        this.iconTarget.classList.remove(this.showClass)
        this.iconTarget.classList.add(this.hideClass)
        this.inputType = 'password'
      }
    }
  }

  get inputType() {
    return this.inputTarget.type
  }

  set inputType(type) {
    return this.inputTarget.type = type
  }
}
