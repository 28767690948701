/* eslint no-console:0 */
import "core-js/stable";
import "regenerator-runtime/runtime";

window.Rails = require("@rails/ujs");
window.Turbolinks = require("turbolinks");
window.ActiveStorage = require("@rails/activestorage");

Turbolinks.start();
ActiveStorage.start();

import { isMobileApp } from "../helpers";
window.isMobileApp = isMobileApp;

require("trix");
require("@rails/actiontext");

require("jquery");
require("jquery-ui/ui/widgets/autocomplete");
require("jquery-ui/ui/widgets/sortable");
require("bootstrap");

// require("select2");

const CsSelect2Locale = require("select2/src/js/select2/i18n/cs");
const Translation = require("select2/src/js/select2/translation");
Translation._cache["./i18n/cs"] = CsSelect2Locale;
Translation._cache["cs"] = CsSelect2Locale;

window.jQuery = $;
window.$ = $;

require("@fortawesome/fontawesome-free/css/all.min.css");

require("../src/update_animal_selection_on_select");
require("../src/build_hunting_form");
require("../src/deactivation_toggler");
require("../src/form_filters");
require("../src/inline_form");
require("../src/seal_creator");
require("../src/sort_columns_on_drag");

require("application");

require("../lib/mapkick");

Rails.start();

let selectInit = [];
let selectInitMultiple = [];

function initApplicationPlugins() {
  const select2ItemTemplate = (item) => {
    return $(`<span title="${item.id}">${item.text}</span>`);
  };
  const singleElem = $(
    "select:not([multiple]):not([data-disable-select2~=true]):not('.flatpickr-monthDropdown-months')"
  );

  singleElem.each((idx, elem) => {
    const $elem = $(elem);
    const params = $elem.hasClass("optional") ? { allowClear: true } : {};

    selectInit.push($elem.select2({
      ...params,
      placeholder: "",
      allowClear: true,
      language: CsSelect2Locale,
      minimumResultsForSearch: 1,
      theme: "bootstrap",
      width: "100%",
      templateResult: select2ItemTemplate,
    }));
  })

  const multipleElem = $("select[multiple='multiple']:not([data-disable-select2~=true]):not('.flatpickr-monthDropdown-months')");

  multipleElem.each((idx, elem) => {
    const $elem = $(elem);
    const params = $elem.hasClass("optional") ? { allowClear: true } : {};

    selectInitMultiple.push($(elem).select2({
      ...params,
      placeholder: "",
      allowClear: true,
      language: CsSelect2Locale,
      minimumResultsForSearch: 1,
      multiple: true,
      theme: "bootstrap",
      width: "100%",
      templateResult: select2ItemTemplate,
    }));
  });

  const clearAddressFilters = (evt, element) => {
    evt.preventDefault();
    form = element.closest("form");
    radiusInput = $(form).find("[data-role~=radius_input]");
    latitudeInput = $(form).find("[data-role~=latitude_input]");
    longitudeInput = $(form).find("[data-role~=longitude_input]");
    radiusInput.val("");
    latitudeInput.val("");
    longitudeInput.val("");
    $(form).submit();
  };
}

const hideAlert = () => {
  $(".alert-success").fadeTo(3000, 500).slideUp(500);
};

const toggleFilters = () => {
  if (document.querySelector("[data-role~=reveal-toggle]")) {
    document
      .querySelector("[data-role~=reveal-toggle]")
      .addEventListener("click", () => {
        document.querySelector("#right-sidebar").classList.toggle("--off");
      });
  }
};

const maybeHideFilters = () => {
  if (isMobileApp) {
    const sidebarElement = document.querySelector("#right-sidebar");
    if (sidebarElement) {
      sidebarElement.classList.add("--off");
    }
  }
};

function reloadWithTurbolinks() {
  let scrollPosition;

  function reload() {
    scrollPosition = [window.scrollX, window.scrollY];
    Turbolinks.visit(window.location.toString(), {
      action: "replace",
    });
  }

  document.addEventListener("turbolinks:load", () => {
    if (scrollPosition) {
      window.scrollTo.apply(window, scrollPosition);
      scrollPosition = null;
    }
  });

  return reload;
}

$(document).on("turbolinks:before-render", () => {
  (selectInit || []).forEach((elem) => {
    if ($(elem).data("select2")) {
      $(elem).select2("destroy");
    }
  });
  selectInit = [];

  (selectInitMultiple || []).forEach((elem) => {
    if ($(elem).data("select2")) {
      $(elem).select2("destroy");
    }
  });
  selectInitMultiple = [];
});

$(document).on("select2:select", function (event) {
  const { target } = event;
  let native = new Event("change", { bubbles: true });
  target.dispatchEvent(native);
});

document.addEventListener("turbolinks:load", () => {
  initApplicationPlugins();
  hideAlert();
  toggleFilters();
  maybeHideFilters();

  if (window.localStorage.getItem("accessible") === 'true') {
    document.documentElement.style.setProperty('--font-size-default', "18px");
    document.body.classList.toggle("accessible");
  }
});

window.initApplicationPlugins = initApplicationPlugins;
window.reloadWithTurbolinks = reloadWithTurbolinks();

window.toggleAccessibility = () => {
  const origValue = getComputedStyle(document.body).getPropertyValue('--font-size-default').trim()
  document.documentElement.style.setProperty('--font-size-default', origValue === "16px" ? "18px" : "16px");
  document.body.classList.toggle("accessible");
  document.dispatchEvent(new Event('resize-scroll-table'));
  window.localStorage.setItem("accessible", document.body.classList.contains("accessible"));
}


