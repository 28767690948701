export default class extends ApplicationController {
  static classes = ["hidden"];
  static targets = ["input"];

  connect() {
    if (document.activeElement == this.element) {
      this.toggle(false);
    } else {
      this.toggle(true);
    }
  }

  show(evt) {
    this.toggle(false);
  }

  hide(evt) {
    this.toggle(true);
  }

  toggle(predicate) {
    if (this.parent) {
      this.parent.classList.toggle(this.hiddenClass, predicate);
    }
  }

  get parent() {
    return this.element.parentNode;
  }
}
