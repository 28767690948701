timestamp = null

$(document).on 'change', '[data-behavior~=validate_hunting_form_on_change]', (evt) ->
  ts = Math.floor(Date.now() / 1000);

  if (evt.currentTarget.getAttribute("type") == "hidden" && timestamp && ts - timestamp < 100)
    timestamp = null
    return
  else
    timestamp = ts

  input = evt.target
  isSealSelector = input.dataset['role'] && input.dataset['role'] == 'hunting_seal_input'
  if isSealSelector
    selected = input.options[input.selectedIndex]
    isNew = selected.dataset['select2Tag']
    if input && isNew
      input.classList.remove('is-invalid')
      validateUrl = input.dataset.url
      return validateSeal(@value, validateUrl)
    else
      return performValidation()
  else
    return performValidation()

$(document).on 'blur', '[data-behavior~=validate_hunting_form_on_blur]', (evt) ->
  performValidation()

performValidation = () ->
  form = document.querySelector('form[data-role~=guild_hunting_form]')
  validateUrl = form.dataset.validateUrl
  validateForm(form, validateUrl)

validateForm = (form, validateUrl) ->
  $.ajax
    url: validateUrl
    method: 'POST'
    data: $(form).serialize()
    dataType: 'script'
    success: (res) ->
      initApplicationPlugins()
      return
    error: (error) ->
      console.error('❌ Error:\n' + error)

validateSeal = (query, url) ->
  if url and query.length
    $.ajax
      url: url
      data: {
        q: by_seal_number: query
      }
      method: 'POST'
      dataType: 'script'
      success: (data) ->
        return
      error: (error) ->
        console.error('❌ Error:\n' + error)
    return

$(document).on 'blur', '[data-behavior~=calculate_total_price_on_blur]', (evt) ->
  input = document.querySelector('form [data-role~=hunting_total_price]')
  if (!input.value)
    form = document.querySelector('form[data-role~=guild_hunting_form]')
    url = form.dataset.calculatePriceUrl
    getTotalPrice(form, url)

getTotalPrice = (form, url) ->
  $.ajax
    url: url
    method: 'POST'
    data: $(form).serialize()
    dataType: 'json'
    success: (data) ->
      input = document.querySelector('form [data-role~=hunting_total_price]')
      value = data.total_price
      if value != undefined
        input.value = value
        document.querySelector('form [data-role~=hunting_formatted_total_price]').innerHTML = data.formatted_total_price
      return
    error: (err) ->
      console.warn err
      input = document.querySelector('form [data-role~=hunting_total_price]')
      input.value = 0
      document.querySelector('form [data-role~=hunting_formatted_total_price]').innerHTML = 0
