export default class extends ApplicationController {

    static targets = [ "form", "page", "rate", "currency"]
    static values = {grouped_view: Boolean}

    initialize() {
        this.temp = ""
        this.eur = 0
        this.usd = 0
    }

    save(event) {
        event.preventDefault()
        if (event.target.tagName == "A") {
            this.fetchPage(event.target.attributes.href.value)
        } else {
            this.fetchPage()
        }
    }

    keypress(event) {
        if (event.charCode == 13) {
            this.save(event)
        }
    }

    saveIfChanged(event) {
       if (event.target.value != this.temp) {
           this.setRate(event)
           this.save(event)
       }
    }

    setRate(event) {
        this[this.currencyTarget.value] = this.rateTarget.value
    }

    getRate(event) {
        this.rateTarget.value = this[event.target.value]
    }

    set(event) {
        this.temp = event.target.value
    }

    switch(event) {
        this.fetchPage()
    }

    fetchPage(url) {
        this.mask()
        const options = {headers: {'X-CSRF-Token': this.csfr}}
        if (url) {
            options.method = "POST"
        } else {
            url = this.url
            options.method = "PUT"
            options.body = new FormData(this.formTarget)
        }
        fetch(url, options)
            .then(response => response.text())
            .then(html => {
                this.pageTarget.innerHTML = html
                this.unmask()
            });
    }

    mask() {
        this.pageTarget.style.opacity = 0.5
        this.pageTarget.style.pointerEvents = 'none'
    }

    unmask() {
        this.pageTarget.style.opacity = 1
        this.pageTarget.style.pointerEvents = 'auto'
    }

    get url() {
        return `${this.formTarget.attributes.action.value}`
    }

    get csfr() {
        return $('meta[name="csrf-token"]').attr('content')
    }

}
