$(document).on 'click', '[data-behavior~=create_seal_on_click]', (evt) ->
  evt.preventDefault()
  evt.stopPropagation()

  input = evt.target
  submitUrl = input.dataset.url
  guildId = input.dataset.guildId
  seriesPrefix = input.dataset.seriesPrefix
  seriesSuffix = input.dataset.seriesSuffix
  seriesNumber = input.dataset.seriesNumber

  createSeal(guildId, seriesPrefix, seriesSuffix, seriesNumber, submitUrl)

createSeal = (guildId, seriesPrefix, seriesSuffix, seriesNumber, submitUrl) ->
  if guildId and seriesPrefix and seriesSuffix and seriesNumber and submitUrl
    $.ajax
      url: submitUrl
      data: {
        seal_emit: {
          series_prefix: seriesPrefix
          series_suffix: seriesSuffix
          series_number: seriesNumber
          async_prevented: true
        }
      }
      method: 'POST'
      dataType: 'script'
      success: (response) ->
        return
      error: (error) ->
        console.error('❌ Error:\n' + error)
    return
