export const onDocumentReady = (callback) => {
  if (document.readyState !== "loading") {
    callback();
  } else {
    document.addEventListener("DOMContentLoaded", callback);
  }
};

export const findElement = (root, selector) => {
  if (typeof root == "string") {
    selector = root;
    root = document;
  }
  return root.querySelector(selector);
};

export const findElements = (root, selector) => {
  if (typeof root == "string") {
    selector = root;
    root = document;
  }
  const elements = root.querySelectorAll(selector);
  return toArray(elements);
};

export const makeElement = (tag, opts = {}) => {
  let element = document.createElement(tag);

  for (const attr in opts) {
    const value = opts[attr];
    element.setAttribute(attr, value);
  }

  return element;
};

export const removeElement = (el) => {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
};

export function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`);
  if (element) {
    return element.getAttribute("content");
  }
}

export function getAuthenticityToken() {
  return getMetaValue("csrf-token");
}

export const dispatchEvent = (element, type, initEvent = {}) => {
  const { disabled } = element;
  const { bubbles, cancelable, detail } = initEvent;
  const event = document.createEvent("Event");

  event.initEvent(type, bubbles || true, cancelable || true);
  event.detail = detail || {};

  try {
    element.disabled = false;
    element.dispatchEvent(event);
  } finally {
    element.disabled = disabled;
  }

  return event;
};

export const toArray = (value) => {
  if (Array.isArray(value)) {
    return value;
  } else if (Array.from) {
    return Array.from(value);
  } else {
    return [].slice.call(value);
  }
};

export const insertAfter = (el, ref) => {
  return ref.parentNode.insertBefore(el, ref.nextSibling);
};

export const getPlaceholderImageURL = (r = 0, g = 0, b = 0, a = 0) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  const size = (canvas.width = canvas.height = 1);
  context.fillStyle = `rgba(${r}, ${g}, ${b}, ${a})`;
  context.fillRect(0, 0, size, size);

  return canvas.toDataURL("image/png");
};

export const refreshWithScrollLock = () => {
  let scrollPosition;

  document.addEventListener(
    "turbolinks:load",
    () => {
      if (scrollPosition) {
        window.scrollTo.apply(window, scrollPosition);
        scrollPosition = null;
      }
    },
    false
  );

  scrollPosition = [window.scrollX, window.scrollY];
  Turbolinks.visit(window.location, { action: "replace" });
};

export const safeParse = (data) => {
  try {
    return JSON.parse(data);
  } catch (_) {
    return null;
  }
};

export const chunk = (input, size) => {
  return input.reduce((arr, item, idx) => {
    return idx % size === 0
      ? [...arr, [item]]
      : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]];
  }, []);
};

export const errorToConsole = (scope, value) => {
  const message = `🚨 %c[${scope} Error]: ${value}`;
  console.log(message, "color: #ff0000; font-weight:bold;");
};

export const flashMessage = (message, status) => {
  const flashContainer = document.querySelector('.fixed-top-flash');
  if (flashContainer) {
    flashContainer.innerHTML += `<div class="alert alert-${status} alert-dismissible fade show" data-controller="element-removal" role="alert">${message} <button type="button" class="close" data-controller="autoclick" data-autoclick-delay-value="4000" data-action="click->element-removal#remove" aria-label="Close"> <span aria-hidden="true">×</span> </button> </div>`;
  }
};

export const getMapEditorConfig = (geometryUpdateDate, tilesUrl, apiKey = '') => ({
  layers: [{
    options: {
      attribution: 'Ortomapa, <a href="https://licence.mapy.cz/?doc=mapy_pu#api" target="_blank">Seznam.cz a.s. a další</a>',
      tileSize: 256
    },
    template: `https://api.mapy.cz/v1/maptiles/aerial/256/{z}/{x}/{y}?lang=cs&apikey=${apiKey}`,
    title: 'Ortomapa',
    type: 'base'
  }, {
    options: {
      attribution: 'Turistická, <a href="https://licence.mapy.cz/?doc=mapy_pu#api" target="_blank">Seznam.cz a.s. a další</a>',
      tileSize: 256
    },
    template: `https://api.mapy.cz/v1/maptiles/outdoor/256/{z}/{x}/{y}?lang=cs&apikey=${apiKey}`,
    title: 'Turistická',
    type: 'base'
  }, {
    options: {
      attribution: 'Zimní, <a href="https://licence.mapy.cz/?doc=mapy_pu#api" target="_blank">Seznam.cz a.s. a další</a>',
      tileSize: 256
    },
    template: `https://api.mapy.cz/v1/maptiles/winter/256/{z}/{x}/{y}?lang=cs&apikey=${apiKey}`,
    title: 'Zimní',
    type: 'base'
  }, {
    options: {
      attribution: 'Základní, <a href="https://licence.mapy.cz/?doc=mapy_pu#api" target="_blank">Seznam.cz a.s. a další</a>',
      tileSize: 256
    },
    template: `https://api.mapy.cz/v1/maptiles/basic/256/{z}/{x}/{y}?lang=cs&apikey=${apiKey}`,
    title: 'Základní',
    type: 'base'
  }, (Boolean(tilesUrl) && {
    options: {
      attribution: 'Hranice',
      tileSize: 256
    },
    template: `${tilesUrl}/guilds/{z}/{x}/{y}.png?${geometryUpdateDate}`,
    title: 'Hranice',
    type: 'overlay'
  }), {
    options: {
      attribution: 'Popisky',
      tileSize: 256
    },
    template: `https://mapserver.mapy.cz/hybrid-sparse-m/{z}-{x}-{y}?sdk=HgUbCgUbGkgqAQkYBxYEHQNHQlJSQFBYQVxbQg%3D%3D&apikey=${apiKey}`,
    title: 'Popisky',
    type: 'overlay'
  }].filter(Boolean),
  maxBounds: [
    [48.05, 11.1],
    [51.75, 20.3],
  ],
  showLayersControl: true
});
