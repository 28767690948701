export default class extends ApplicationController {
  static classes = ["hidden"];

  connect() {
    this.element.classList.toggle(this.hiddenClass, this.isChrome);
  }

  // Private

  get isChrome() {
    return (
      this.isChromium &&
      (this.vendor === "Google Inc." ||
        (/Chrome/.test(navigator.userAgent) &&
          /Google Inc/.test(this.vendor))) &&
      !this.isOpera &&
      !this.isEedge
    );
  }

  get isChromium() {
    return !!window.chrome;
  }

  get isOpera() {
    return typeof window.opr !== "undefined";
  }

  get isEedge() {
    return (
      navigator.userAgent.indexOf("Edge") > -1 ||
      navigator.userAgent.indexOf("Edg") > -1
    );
  }

  get vendor() {
    return navigator.vendor;
  }
}
