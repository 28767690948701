export default class extends ApplicationController {
    static targets = ["name", "amount", "price", "day_part", "companion", "date"]
    static values = {url: String, type: String}
    items

    initialize() {
        fetch(this.urlValue)
            .then(response => response.json()
                .then(data => {
                        this.items = data
                    }
                )
            )
    }

    connect() {
        this.setUp()
    }

    select(event) {
        let id = event.target.value
        //if (!id || id < 1) return

        let result = this.items.find(item =>
            item.id === id
        )

        this.nameTarget.value = result.name
        this.day_partTarget.value = result.day_part
        this.priceTarget.value = Math.round(result.price * 100) / 100
        this.amountTarget.value = 1
        this.setUp()
    }

    setUp() {
        if (this.typeValue != 'companion') return
        if (this.day_partTarget.value != "") {
            this.mask()
        } else {
            this.unmask()
        }
    }

    mask() {
        this.companionTargets.forEach(function(el) {
            el.style.opacity = 0.5
            el.style.pointerEvents = 'none'
        })
        if (this.hasDateTarget) this.dateTarget.classList.remove("visually-hidden")
    }

    unmask() {
        this.companionTargets.forEach(function(el) {
            el.style.opacity = 1
            el.style.pointerEvents = 'auto'
        })
        if (this.hasDateTarget) this.dateTarget.classList.add("visually-hidden")
    }

}
