$(document).on 'change', '[data-behavior~=toggle_deactivation_section_on_change]', (evt) ->
  element = document.getElementById('deactivation_section')
  toggler = document.querySelector('[data-role~=deactivate_toggle]')
  if @checked
    element.style.display = 'block'
    toggler.style.display = 'none'
  else
    element.style.display = 'none'
    toggler.style.display = null

$(document).on 'click', '[data-behavior~=toggle_deactivation_section_on_click]', (evt) ->
  evt.preventDefault()
  evt.stopPropagation()
  input = document.querySelector('[data-role~=deactivate_input]')
  input.click()
