export default class extends ApplicationController {
  connect() {
    $(document).on("select2:open", () => this.handleSelectOpened());

    $("a[rel~=popover], .has-popover").popover();
    $("a[rel~=tooltip], .has-tooltip").tooltip();
  }

  // Private

  handleSelectOpened() {
    document
      .querySelector(".select2-container--open .select2-search__field")
      .focus();
  }
}
