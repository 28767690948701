import Cleave from "cleave.js";
import flatpickr from "flatpickr";
import { Czech } from "flatpickr/dist/l10n/cs.js";
import { isMobileApp } from "../helpers";

export default class extends ApplicationController {
  static datepickr;
  static targets = ["input"];
  static values = { type: String, platform: String };

  connect() {
    if (!this.halt) {
      this.initializeInput();
    }

    if (this.halt) {
      switch (this.typeValue) {
        case "time":
          this.element.type = "time";
          break;
        case "date":
          this.element.type = "date";
          break;
        case "datetime":
          this.element.type = "datetime-local";
          break;
        default:
          break;
      }
    }
  }

  disconnect() {
    if (!this.halt) {
      this.destroyInput();
    }

    this.datepickr = null;
  }

  handleChange(event) {
    const target = event.currentTarget;
    if (this.typeValue !== "time") this.initClearButton(target);
  }

  initClearButton(element) {
    const value = element.value;
    const parent = element.parentElement;
    const button = parent.querySelector(
      "[data-target~=datepickr-clear-button]"
    );

    if (value === "") {
      if (button == null) return;

      button.removeEventListener("click", this.clearInput);
      button.remove();
    } else {
      if (button == null) {
        element.insertAdjacentHTML("afterend", this.buttonHtml);

        const initializedButton = parent.querySelector(
          "[data-target~=datepickr-clear-button]"
        );
        initializedButton.addEventListener("click", this.clearInput.bind(this));
      }
    }
  }

  clearInput(event) {
    this.datepickr.clear();
  }

  initializeInput() {
    switch (this.typeValue) {
      case "time":
        this.datepickr = new Cleave(this.element, this.options);
        this.element.placeholder = "HH:MM";
        break;
      case "date":
        this.datepickr = flatpickr(this.element, this.options);
        this.initClearButton(this.element);
        break;
      case "datetime":
        this.datepickr = flatpickr(this.element, this.options);
        this.initClearButton(this.element);
        break;
      default:
        break;
    }
  }

  destroyInput() {
    switch (this.typeValue) {
      case "date":
        this.datepickr.destroy();
        break;
      case "datetime":
        this.datepickr.destroy();
        break;
      default:
        break;
    }
  }

  get halt() {
    if (!this.hasPlatformValue) return false;

    switch (this.platformValue) {
      case "mobile":
        return isMobileApp ? false : true;
        break;
      case "desktop":
        return isMobileApp ? true : false;
        break;
      default:
        return false;
        break;
    }
  }

  get buttonHtml() {
    return `
      <span class='d-flex align-items-center justify-content-end font-bold position-absolute' data-target='datepickr-clear-button'>
        <span class='text-small text-muted'>✖</span>
      </span>
    `.trim();
  }

  get options() {
    switch (this.typeValue) {
      case "time":
        return Object.assign(
          {},
          {
            time: true,
            timePattern: ["h", "m"],
          }
        );
        break;
      case "date":
        return Object.assign(
          {},
          {
            enableTime: false,
            allowInput: true,
            altInput: true,
            altFormat: "d.m.Y",
            dateFormat: "d.m.Y",
            locale: Czech,
            onClose(dates, currentdatestring, picker) {
              picker.setDate(
                picker.altInput.value,
                false,
                picker.config.altFormat
              );
            },
          }
        );
        break;
      case "datetime":
        return Object.assign(
          {},
          {
            enableTime: true,
            altInput: true,
            altFormat: "d.m.Y H:i",
            dateFormat: "d.m.Y H:i",
            time_24hr: true,
            locale: Czech,
          }
        );
        break;
      default:
        break;
    }
  }
}
