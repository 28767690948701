import { toArray } from "../lib/utils";

export default class extends ApplicationController {
  static targets = ["add", "template", "toggleable"];
  static values = { maxCount: Number };

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "nested-fields";
  }

  addAssociation(event) {
    event.preventDefault();
    if (this.allowanceExceeded) return;

    const content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      new Date().valueOf()
    );
    this.addTarget.insertAdjacentHTML("beforebegin", content);
    this.toggleAddButton();
  }

  removeAssociation(event) {
    event.preventDefault();
    let item = event.currentTarget.closest(`[data-role~=${this.wrapperClass}]`);

    if (item.dataset.newRecord === "true") {
      item.remove();
    } else {
      item.querySelector('input[name*="_destroy"]').value = 1;
      item.style.display = "none";
    }
    this.toggleAddButton();
  }

  toggleAddButton() {
    if (this.hasToggleableTarget) {
      this.toggleableTarget.classList.toggle(
        "visually-hidden",
        this.allowanceExceeded
      );
    }
  }

  get allowanceExceeded() {
    const elements = this.element.querySelectorAll(
      `[data-role~=${this.wrapperClass}]`
    );
    const visibleElements = toArray(elements).filter((item, index) => {
      return item.style.display != "none";
    });

    return this.hasMaxCountValue && this.maxCountValue <= visibleElements.length
      ? true
      : false;
  }
}
