$(document).on 'select2:select', '[data-behavior~=update_animal_selection_on_select]', (evt) ->
  select = evt.target
  selected = evt.params.data.id
  if selected.length
    submitUrl = select.dataset.submitUrl
    createItem(selected, submitUrl)
  else
    console.log('noop')

createItem = (query, submitUrl) ->
  $.ajax
    url: submitUrl
    method: 'POST'
    data:
      query: query
    dataType: 'script'
    success: (res) ->
      return
    error: (err) ->
      console.warn err
