export default class extends ApplicationController {
    static targets = [ "editable", "editable_select", "edit_button", "submit_button", "ares_button"]
    static values = { edited: Boolean}

    connect() {
        if (this.editedValue) this.edit()
    }

    edit(event) {
        if (event) event.preventDefault();
        //this.invoiceTarget.classList.remove(this.hiddenClass);
        this.editableTargets.forEach((editable) => {
            editable.readOnly = false
        })
        this.editable_selectTargets.forEach((editable) => {
            editable.disabled = false
        })
        this.edit_buttonTargets[0].classList.toggle("visually-hidden", true)
        this.submit_buttonTargets[0].classList.toggle("visually-hidden", false)
        this.ares_buttonTargets[0].classList.toggle("visually-hidden", false)
    }

}
