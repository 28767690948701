export default class extends ApplicationController {
    static targets = [ "order", "invoice", "years", "price", "total" ]
    static classes = ["hidden"];
    static values = {type: String, price: Number}

    initialize() {
        console.log("init licence")
        if (this.hasYearsTarget) this.countTotal()
        if (this.typeValue == "invalid") this.order()
    }

    order(event) {
        if (event) event.preventDefault();
        this.orderTarget.classList.add(this.hiddenClass);
        this.invoiceTarget.classList.remove(this.hiddenClass);
    }

    countTotal() {
        let years = parseInt(this.yearsTarget.value, 10)
        let price = parseInt(this.priceTarget.value, 10)
        price = parseInt(price / 100) * 100
        if (price < this.priceValue) {
            price = this.priceValue
        }
        this.priceTarget.value = price
        this.totalTarget.value = (years * price) + " Kč"
    }

}
