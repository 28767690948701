import SelectorObserver from './selector_observer'

observer = new SelectorObserver()

observer.add '[data-role~=sortable_column]', (sortables) ->
  $(sortables).sortable(
    cursor: 'move'
    handle: '[data-role~=sortable_handle]'
    receive: (evt, ui) ->
      receiver = evt.target
      sender = ui.sender[0]
      dragged = ui.item[0]

      receiverModel = receiver.getAttribute('data-model')
      senderModel = sender.getAttribute('data-model')

      if receiverModel == 'view_column' and senderModel == 'available_column'
        code = dragged.getAttribute('data-resource-id')
        position = $(dragged).index()
        $.ajax
          url: receiver.getAttribute('data-base-url')
          type: 'POST'
          dataType: 'html'
          data:
            view_column:
              code: code
              position: position
          complete: (xhr) ->
            refreshPage(xhr)
      else if receiverModel == 'available_column' and senderModel == 'view_column'
        itemId = dragged.getAttribute('data-resource-id')
        url = receiver.getAttribute('data-base-url')
        $.ajax
          url: url + '/' + itemId
          type: 'DELETE'
          dataType: 'html'
          complete: (xhr) ->
            refreshPage(xhr)
      else
        return
      return
    update: (evt, ui) ->
      receiver = evt.target
      receiverModel = receiver.getAttribute('data-model')

      if receiverModel == 'view_column' and ui.sender == null
        dragged = ui.item[0]
        itemId = dragged.getAttribute('data-resource-id')
        position = ui.item.index()
        url = receiver.getAttribute('data-base-url')
        $.ajax
          url: url + '/' + itemId
          type: 'PATCH'
          data:
            view_column:
              position: position
          complete: (xhr) ->
            refreshPage(xhr)
      return
    connectWith: '[data-role~=sortable_column]')
  return

getCurrentSnapshot = () ->
  Turbolinks.Snapshot.fromHTMLElement document

dispatchReload = () ->
  Turbolinks.dispatch("turbolinks:load")

refreshPage = (xhr) ->
  referrer = window.location.href
  currentSnapshot = getCurrentSnapshot()
  newSnapshot = Turbolinks.Snapshot.wrap xhr.responseText
  Turbolinks.controller.currentVisit = Turbolinks.controller.createVisit referrer, "restore", {}
  Turbolinks.clearCache()
  Turbolinks.SnapshotRenderer.render Turbolinks.controller, dispatchReload, currentSnapshot, newSnapshot
