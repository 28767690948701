import { v4 as uuid } from "uuid";
import { request } from "../helpers";
import {
  dispatchEvent,
  flashMessage,
  getMapEditorConfig
} from "../lib/utils";

require("../lib/polygon-map-editor");
export default class extends ApplicationController {
  static editor;
  static polygonCache;
  static values = {
    fetchUrl: String,
    geometryUpdateDate: String,
    huntingGroundId: Number,
    mapApiKey: String,
    paramName: String,
    redirectUrl: String,
    saveUrl: String,
    tilesUrl: String,
  };

  connect() {
    this.editor = new window.PolygonMapEditor(this.element, getMapEditorConfig(this.geometryUpdateDateValue || '', this.tilesUrlValue, this.mapApiKeyValue));

    this.polygonCache = {};

    if (this.element) {
      const buttonElement = document.createElement('button');
      buttonElement.className = 'btn btn-success btn-save-suggestion';
      buttonElement.innerHTML = 'Odeslat návrh';
      buttonElement.addEventListener(
        'click',
        this.performUpdate.bind(this)
      );
      this.element.appendChild(buttonElement);
    }

    document.addEventListener("map:ready", this.initializeMap.bind(this));

    document.addEventListener(
      "map:polygon-modified",
      this.updateCache.bind(this)
    );
  }

  disconnect() {
    if (this.editor) {
      this.editor.disconnect();
      this.editor = null;
    }
    this.polygonCache = {};
  }

  initializeMap({ detail: { map }}) {
    this.fetchGeometries();
    // Initialize with "Edit polygon points" tool
    setTimeout(() => {
      const el = document.querySelector('[title="Edit polygon points"]');
      if (el) {
        el.click();
      }
    }, 300);
  }

  fetchGeometries() {
    this.fetchData(this.initializeGeometries.bind(this));
  }

  updateCache(event) {
    const { detail } = event;
    const identifier = detail.id;
    const coordinates = detail.points.map((x) => [x.lat, x.lng]);

    this.polygonCache[identifier] = coordinates;
  }

  async performUpdate() {
    const ids = Object.keys(this.polygonCache);

    if (ids.length) {
      const identifier = ids[0];
      const coordinates = this.polygonCache[identifier];

      console.info(`🚀 Create suggestion for polygon ID ${identifier}`);

      let formData = new FormData();
      formData.append(`${this.paramNameValue}[boundaries]`, coordinates);
      formData.append(`${this.paramNameValue}[hunting_ground_id]`, this.huntingGroundIdValue);

      try {
        const response = await request.post(
          `${this.saveUrlValue}`,
          {
            body: formData,
            responseKind: "json",
          }
        );
        if (response) {
          flashMessage('Navrh úpravy hranic byl úspěšně uložen.', 'success');
          window.location.href = this.redirectUrlValue;
        }
      } catch (err) {
        flashMessage('Při ukládání navrhu došlo k chybě', 'danger');
        errorToConsole(err.message);
      }

    }
  }

  initializeGeometries(geometries) {
    let coords = [];

    geometries.forEach((geometry) => {
      const {
        id,
        type,
        coordinates,
        options: { label, style, verified },
      } = geometry;

      if (this.editor) {
        const polygon = this.editor.addPolygon(id, type, coordinates, style);

        this.polygonCache[id] = coordinates;

        coords.push(coordinates);

        this.editor.setPolygonVerified(id, verified)
      }
    });

    this.setCenter(coords);
  }

  async fetchData(callback) {
    try {
      const response = await request.get(this.fetchUrlValue, {
        contentType: "application/json",
        responseKind: "json",
      });

      callback(response);
    } catch (err) {
      throw err;
      errorToConsole(err.message);
    }
  }

  setCenter(coords) {
    if (coords.length === 0) return;
    this.map.fitBounds(coords);
  }

  get map() {
    if (this.editor == null) return;
    return this.editor.map;
  }
}

const errorToConsole = (value) => {
  const message = `🚨 %c[MapEditor Error]: ${value}`;
};
