export default class extends ApplicationController {

    static targets = ["ico"]

    get_ares(event) {
        console.log("get ares")
        this.fetchAres()
    }

    fetchAres() {
        fetch(`http://wwwinfo.mfcr.cz/cgi-bin/ares/darv_bas.cgi?ico=${this.icoTarget.value}`)
            .then(response => response.text())
            .then(xml => {
                //this.pageTarget.innerHTML = html
                console.log($.parseXML(xml))
            });
    }
}

