export default class extends ApplicationController {
  static values = { to: String };

  copy(event) {
    if (this.connected) {
      this.connected.value = event.target.value;
    }
  }

  get connected() {
    if (this.hasToValue) {
      return document.getElementById(this.toValue);
    }
  }
}
