import Cleave from "cleave.js";
import { debounce } from "../helpers";

const getLanguage = () => {
  if (navigator.languages != undefined) return navigator.languages[0];
  return navigator.language;
};

export default class extends ApplicationController {
  static masked;
  static values = {
    minimumIntegerDigits: Number,
    options: Object,
    type: String,
  };

  connect() {
    const defaultValue = this.element.value;
    this.masked = new Cleave(this.element, this.props);
    this.masked.setRawValue(defaultValue);
  }

  disconnect() {
    this.masked = null;
  }

  maybeAddDigits({ target: { rawValue } }) {
    if (this.hasMinimumIntegerDigitsValue) {
      if (rawValue) {
        const value = parseInt(rawValue);

        if (value === 0) return;

        const formattedNumber = value.toLocaleString(getLanguage(), {
          minimumIntegerDigits: this.minimumIntegerDigitsValue,
          useGrouping: false,
        });

        this.element.value = formattedNumber;
      }
    }
  }

  get props() {
    switch (this.typeValue) {
      case "numeric":
        return Object.assign(
          {},
          {
            numericOnly: true,
            onValueChanged: debounce(this.maybeAddDigits.bind(this), 1000),
            ...this.optionsValue,
          }
        );
        break;
      case "guild":
        return Object.assign(
          {},
          {
            numericOnly: true,
            delimiter: " ",
            prefix: "CZ",
            blocks: [2, 10],
            ...this.optionsValue,
          }
        );
        break;
      default:
        return Object.assign({}, { ...this.optionsValue });
        break;
    }
  }
}
