import { request } from "../helpers";
import { errorToConsole } from "../lib/utils";

export default class extends ApplicationController {
  static values = { url: String, confirm: String };

  async remove(event) {
    event.preventDefault();
    event.stopPropagation();

    try {
        if (this.confirmValue.length) {
            if (!confirm(this.confirmValue)) return
        }
        const response = await request.delete(this.urlValue, {
        responseKind: "json",
      });

      this.element.remove();
    } catch (err) {
      errorToConsole("PhotoRemoval", err.message);
    }
  }
}
